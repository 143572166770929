<template>
  <div class="manage">
    <el-dialog width="1000px" :title="operateType === 'add' ? '新增合同' : '修改合同'" :visible.sync="isShow" :close-on-click-modal="false">
      <contract-form :contractForm="contractForm" ref="contractForm"></contract-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('contractForm')">确 定</el-button>
      </div>
    </el-dialog>
    <div class="manage-header">
      <el-button type="primary" :disabled="usertype.id !== 5" @click="addContract">+ 新增</el-button>
      <el-select v-model="company" placeholder="请选择分公司" v-if="currentUserCompany === '00'" @change="getCompany">
        <el-option v-for="item in companys_options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>

      <div class="searchdiv">
        <input class="searchinput" type="text" v-model="keyword" placeholder="编号 | 名称 | 签约人 | 项目 | 联系人 | 电话 | 负责人" @keyup.enter="getKeyword" />
        <button type="button" class="searchbutton" @click="getKeyword">搜索</button>
      </div>
    </div>

    <common-table
      :tableData="tableData"
      :config="config"
      :usertype="usertype"
      @getSelectedRows="getSelectedRows"
      @changePage="getList"
      @edit="editContract"
      @del="delContract"
    >
    </common-table>
  </div>
</template>

<script>
// import CommonForm from '../../components/CommonForm'
import CommonTable from './ContractTable'
import ContractForm from './ContractForm'
import utils from '../../assets/js/utils'

// import Cookie from 'js-cookie'

export default {
  components: {
    // CommonForm,
    CommonTable,
    ContractForm
  },
  data() {
    return {
      isShow: false,
      operateType: 'add',
      tableData: [],
      usertype: '',
      user_realname: '',
      currentUserCompany: utils.getUserCookie().company.code,

      company: utils.getCompanyCookie(),
      companys_options: [],
      keyword: '',

      config: {
        page: 1,
        total: 30,
        loading: false
      },

      contractForm: {
        contract_id: '',
        contract_name: '',
        signing_date: '',
        contractor: '',
        first_party: '',
        project: '',
        contact: '',
        contact_phone: '',
        contract_amount: '',
        real_amount: '',
        collection_person: '',
        construction_material: ''
      }
    }
  },
  watch: {
    'config.page': function() {
      utils.setPageCookie(this.config.page)
    }
  },

  methods: {
    //获取分公司列表
    getCompanyList() {
      this.$axios.get('/getCompanyList/').then(res => {
        console.log('companys', res.data)
        res.data.forEach(element => {
          let company = {}
          company.label = element.name
          company.value = element.code
          this.companys_options.push(company)
        })
        console.log('companys_options', this.companys_options)
      })
    },

    // 获取合同列表
    getList() {
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      //判断是否是从详情页返回的
      let from = this.$route.query.from
      if (from === 'detail') {
        this.keyword = utils.getKeywordCookie()
        this.company = utils.getCompanyCookie()
        this.config.page = utils.getPageCookie()
        // alert(this.config.page)
        this.$route.query.from = ''
        console.log('this.keyword', this.keyword)
        console.log('this.company', this.company)
      }
      console.log('user:', user)
      console.log('usertype:', this.usertype)
      console.log('user_realname:', this.user_realname)
      console.log('company:', this.company)
      this.config.loading = true
      const api = '/getContracts/'
      this.$axios
        .get(api, {
          params: {
            page: this.config.page,
            company: this.company, //公司编号code
            keyword: this.keyword
          }
        })
        .then(res => {
          console.log('res.data', res.data)

          this.config.total = res.data.totalcount
          console.log('total:', this.config.total)
          let data = res.data.data
          //排序
          data.sort(function(a, b) {
            //按照添加时间倒序
            // console.log(a.added_person_time)
            // console.log(b.added_person_time)
            let a_date = utils.stringToDate(a.added_person_time)
            let b_date = utils.stringToDate(b.added_person_time)
            return a_date < b_date ? 1 : -1
          })
          this.tableData = data.map(item => {
            item.financial_confirm_label = item.financial_confirm === true ? item.financial_confirm_person + '\n' + item.financial_confirm_time : '未确认'
            item.legal_audit_and_time = item.legal_audit + '\n' + item.legal_audit_time
            item.contract = item.contract_id + '\n' + item.contract_name
            item.signing = item.contractor + '\n' + item.signing_date
            item.second_party_project = item.second_party + '\n' + item.project
            item.financial_audit_and_time = item.financial_audit + '\n' + item.financial_audit_time
            item.added = item.added_person + '\n' + item.added_person_time
            return item
          })

          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //获取选择的分公司
    getCompany() {
      utils.setCompanyCookie(this.company)
      console.log('company_cookie', this.company)
      this.getList()
    },
    //获取搜索关键词
    getKeyword() {
      // alert(this.keyword)
      utils.setKeywordCookie(this.keyword)
      this.config.page = 1
      console.log('keyword_cookie', this.keyword)
      this.getList()
    },
    //增加合同
    addContract() {
      this.contractForm = {}
      this.operateType = 'add'
      this.isShow = true
      // this.$router.push({ name: 'addcontract' })
    },

    //编辑合同
    editContract(row) {
      console.log('row........', row)
      this.operateType = 'edit'
      this.isShow = true
      row.contract_amount = parseInt(row.contract_amount)
      // row.real_amount = parseInt(row.real_amount)
      // row.signing_date = new Date(row.signing_date)
      this.contractForm = row
      console.log('The new row:', this.contractForm)
    },

    //删除合同
    delContract(row) {
      this.$confirm('此操作将永久删除该合同, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let contract_id = row.contract_id
          this.$axios
            .get('/deleteContract/', {
              params: {
                contract_id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getList()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    //法务审核
    // Legal_audit(row) {
    //   this.$confirm('确认通过法律审核吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       let operateTime = utils.getDateTime()
    //       console.log(operateTime)
    //       let params = {
    //         contract_id: row.contract_id,
    //         user: this.user_realname,
    //         operatetime: operateTime
    //       }

    //       this.$axios.put('/legalAudit/', params).then(res => {
    //         console.log(res.data)
    //         this.$message({
    //           type: 'success',
    //           message: '法务审核成功!'
    //         })
    //         this.getList()
    //       })
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消法务审核'
    //       })
    //     })
    // },

    // //财务审核
    // Financial_audit(row) {
    //   this.$confirm('确认通过财务审核吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       let operateTime = utils.getDateTime()
    //       console.log(operateTime)
    //       let params = {
    //         contract_id: row.contract_id,
    //         user: this.user_realname,
    //         operatetime: operateTime
    //       }

    //       this.$axios.put('/financialAudit/', params).then(res => {
    //         console.log(res.data)
    //         this.$message({
    //           type: 'success',
    //           message: '财务审核成功!'
    //         })
    //         this.getList()
    //       })
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消财务审核'
    //       })
    //     })
    // },

    //结单
    Statement(row) {
      this.$confirm('确认结单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            contract_id: row.contract_id
          }

          this.$axios.put('/statement/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '结单成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消结单'
          })
        })
    },

    // //财务确认
    // Financial_confirm(row) {
    //   this.$confirm('财务确认吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       let params = {
    //         contract_id: row.contract_id,
    //         user: this.user_realname,
    //         operatetime: utils.getDateTime()
    //       }

    //       this.$axios.put('/financialConfirm/', params).then(res => {
    //         console.log(res.data)
    //         this.$message({
    //           type: 'success',
    //           message: '财务确认成功!',
    //           duration: 2000
    //         })
    //         this.getList()
    //       })
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消财务确认',
    //         duration: 2000
    //       })
    //     })
    // },

    getSelectedRows(val) {
      console.log('Selected rows:', val)
    },
    submitForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.operateType === 'edit') {
          //编辑当前合同
          console.log('The new row 2:', this.contractForm)
          this.contractForm.company = utils.getAlphaInString(this.contractForm.contract_id)
          this.$axios.put('/updateContract/', this.contractForm).then(res => {
            console.log('response:', res.data)
            if (res.data.code === 1000) {
              this.$message({
                type: 'success',
                message: '更新合同成功!',
                duration: 2000
              })
              this.isShow = false
              this.getList()
            } else {
              this.$message({
                type: 'warning',
                message: res.data.msg,
                duration: 2000
              })
            }
          })
        } else {
          //增加合同
          console.log('add contract:', this.contractForm)
          this.contractForm.added_person = this.user_realname
          this.contractForm.added_person_time = utils.getDateTime()
          this.contractForm.real_amount = this.contractForm.contract_amount
          this.contractForm.company = utils.getAlphaInString(this.contractForm.contract_id)
          console.log('add contract2222:', this.contractForm)
          this.$axios.post('/addContract/', this.contractForm).then(res => {
            console.log('response:', res.data)
            if (res.data.code === 1000) {
              this.$message({
                type: 'success',
                message: '增加合同成功!',
                duration: 2000
              })
              this.isShow = false
              this.getList()
            } else {
              this.$message({
                type: 'warning',
                message: res.data.msg,
                duration: 2000
              })
            }
          })
        }
      } else {
        console.log('校验不通过！')
      }
    }
  },
  created() {
    this.getCompanyList()
    this.getList()
  }
}
</script>
<style scoped>
.searchdiv {
  height: 40px;
  line-height: 40px;
}
.searchinput {
  width: 350px;
  height: 35px;
  text-align: center;
}
.searchbutton {
  width: 80px;
  height: 40px;
  background-color: #409eff;
}
</style>
