<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      :highlight-current-row="false"
      height="90%"
      v-loading="config.loading"
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column prop="contract" label="合同" width="180" sortable>
        <template slot-scope="scope">
          <router-link :to="{ name: 'contractdetail', query: { id: scope.row.id } }">
            <a :class="scope.row.financial_confirm === false ? 'notFinancialConfirmRow' : 'isFinancialConfirmRow'">{{ scope.row.contract }}</a>
          </router-link>
        </template>
      </el-table-column>

      <el-table-column prop="signing" label="签约" width="100"> </el-table-column>
      <el-table-column prop="second_party_project" label="乙方&工程" width="280"> </el-table-column>
      <el-table-column prop="faren" label="乙方法人" width="80"> </el-table-column>
      <el-table-column prop="contact" label="联系人" width="80"> </el-table-column>
      <el-table-column prop="contract_amount" label="合同金额" width="100"> </el-table-column>
      <!-- <el-table-column prop="real_amount" label="实际金额" width="100"> </el-table-column> -->
      <el-table-column prop="collection_person_name" label="回款负责人" width="120" sortable> </el-table-column>
      <el-table-column prop="legal_audit_and_time" label="法务审核" width="130"> </el-table-column>
      <el-table-column prop="financial_audit_and_time" label="财务审核" width="130"> </el-table-column>
      <el-table-column prop="is_statement" label="结单" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_statement === false ? 'primary' : 'success'">{{ scope.row.is_statement === false ? '未结单' : '已结单' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="financial_confirm_label" label="财务确认" width="100">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.financial_confirm === false">未确认</el-tag>
          <span v-html="scope.row.financial_confirm_label" v-if="scope.row.financial_confirm === true"></span>
        </template>
      </el-table-column>
      <el-table-column prop="added" label="添加" width="130"> </el-table-column>

      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" v-if="usertype.contract_edit">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
          <!-- <el-button size="mini" @click="handleLegal_audit(scope.row)" :disabled="!scope.row.legal_audit ? false : true" v-if="usertype.contract_legal_audit">
            {{ !scope.row.legal_audit ? '法务审核' : '法务已审核' }}</el-button
          > -->
          <!-- <el-button
            size="mini"
            @click="handleFinancial_audit(scope.row)"
            :disabled="!scope.row.financial_audit ? false : true"
            v-if="usertype.contract_financial_audit"
            >{{ !scope.row.financial_audit ? '财务审核' : '财务已审核' }}</el-button
          > -->
          <!-- <el-button size="mini" @click="handleStatement(scope.row)" v-if="usertype.contract_is_statement">结单</el-button> -->
          <!-- <el-button
            size="mini"
            @click="handleFinancial_confirm(scope.row)"
            :disabled="!scope.row.financial_confirm ? false : true"
            v-if="usertype.contract_financial_confirm"
            >{{ !scope.row.financial_confirm ? '财务确认' : '财务已确认' }}</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pager"
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'

export default {
  data() {
    return {
      pagesize: 20,
      multipleSelection: []
    }
  },
  props: {
    tableData: Array,
    config: Object,
    usertype: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.usertype.contract_del) {
          if (this.usertype.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
  },
  methods: {
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },

    handleEdit(row) {
      this.$emit('edit', row)
    },
    handleDelete(row) {
      this.$emit('del', row)
    },
    // handleLegal_audit(row) {
    //   this.$emit('Legal_audit', row)
    // },
    // handleFinancial_audit(row) {
    //   this.$emit('Financial_audit', row)
    // },
    // handleStatement(row) {
    //   this.$emit('Statement', row)
    // },
    // handleFinancial_confirm(row) {
    //   this.$emit('Financial_confirm', row)
    // },
    changePage(page) {
      this.$emit('changePage', page)
    },
    handleSelectionChange(val) {
      // console.log('child Selected rows:', val)
      this.$emit('getSelectedRows', val)
    },

    tableRowClassName({ row, rowIndex }) {
      // console.log(row)
      // console.log(rowIndex)
      if (row.is_statement === true) {
        return 'jiedan-row'
      } else if (rowIndex % 2 === 0) {
        return 'stripe-row'
      }
      return ''
    }
  }
}
</script>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
<style scoped>
.isFinancialConfirmRow {
  color: blue;
}
</style>
